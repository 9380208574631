import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Produkter = ({ data }) => {
  return (
    <Layout wider>
      <Seo title="Produkter" />

      <section className="produkter-main">
        <div className="container-fluid wider">
          <h1>Produkter</h1>
          <p id="breadcrumbs">
            <span>
              <Link to="/">Home</Link> /{" "}
              <span className="breadcrumb_last">Produkter</span>
            </span>
          </p>
          <div className="row">
            <div className="col-md-3">
              <div className="produkter-main-sidebar">
                <h2>Produktmeny</h2>
                {data?.allWpProdukter?.nodes
                  ?.filter(item => item.parentId === null)
                  .map((item, index) => (
                    <Link to={item.uri} key={index}>
                      <h4>{item.title}</h4>
                    </Link>
                  ))}
              </div>
            </div>
            <div className="col-md-9">
              <div className="produkter-main-content">
                <div className="row">
                  {data?.allWpProdukter?.nodes
                    ?.filter(item => item.parentId === null)
                    .map((item, index) => (
                      <div className="col-md-6" key={index}>
                        <Link to={item.uri} className="produkt-tile">
                          <div
                            className="produkt-tile-thumb bigger"
                            style={{
                              backgroundImage: `url(${item?.featuredImage?.node?.localFile?.publicURL})`,
                            }}
                          ></div>
                          <h4>{item.title}</h4>
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpProdukter(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        content
        uri
        parentId
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Produkter
